@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .paused {
    animation-play-state: paused;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: 'Roslindale';
  src: url('./fonts/Roslindale-TextRegular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Urbanist';
  src: url('./fonts/Urbanist-SemiBold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Bitter';
  src: url('./fonts/Bitter-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'OpenSans';
  src: url('./fonts/OpenSans-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('./fonts/Roboto-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Helvetica LT Pro';
  src: url('./fonts/HelveticaLTPro-Roman.otf');
  font-weight: 400;
}

/* https://fonts.google.com/specimen/Open+Sans */

body {
  max-width: 100vw;
  min-height: 100vh;
  font-family: 'Helvetica LT Pro', 'Roslindale', 'Roboto', sans-serif;
  font-style: normal;
  font-optical-sizing: auto;
  font-weight: 400;
  line-height: 1.3;
  overflow-x: hidden;
}

.body-white {
  background: white;
}

.body-gray {
  background: #F0F2F5;
}

body {
  overflow: auto;
}

body::-webkit-scrollbar {
  width: 12px;
  /* Width of vertical scrollbar */
  height: 12px;
}

body::-webkit-scrollbar-track {
  background-color: transparent;
  /* Color of scrollbar track */
}

body::-webkit-scrollbar-thumb {
  background-color: #ccc;
  /* Color of scrollbar thumb */
  border-radius: 6px;
  /* Rounded corners of scrollbar thumb */
}

a {
  color: inherit;
  text-decoration: none;
}

/*upload file*/
::-webkit-file-upload-button {
  cursor: pointer;
}

/* autofill*/

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

/* swiper */

.swiperAuth .swiper-pagination-bullet {
  width: 8px !important;
  height: 8px !important;
  background-color: transparent !important;
  border: 1px solid #fff !important;
  cursor: pointer !important;
}

.swiperAuth .swiper-pagination-bullet-active {
  background-color: #fff !important;
}

.swiperAuth .swiper-pagination {
  bottom: 0;
}

.swiperHomeTop {
  width: 100vw;
  height: 140px;
}

.swiperHomeTop .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiperHomeTop .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* scroll && dots in Funding History */

.scroll-indicator {
  position: fixed;
  bottom: 20px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 999;
}

.scroll-dot {
  width: 10px;
  height: 10px;
  background-color: #0a2540;
  border-radius: 50%;
  margin: 4px;
  opacity: 1;
}

.scroll-dot.active {
  opacity: 1;
}

.scroll-dots {
  display: flex;
  justify-content: center;
  margin-top: -15px;
}

.scroll-dot {
  width: 6px;
  height: 6px;
  border: 1px solid #0a2540;
  background-color: transparent;
  border-radius: 50%;
  margin: 4px;
  opacity: 1;
}

.scroll-dot.active {
  background-color: #0a2540;
}

.hide-scrollbar ::-webkit-scrollbar {
  display: none;
}

.think-scroll::-webkit-scrollbar {
  width: 4px; /* scrollbar width */
}

.think-scroll::-webkit-scrollbar-track {
  background: #f0f2f5; /* scrollbar track color */
  border-radius: 2px;
}

.think-scroll::-webkit-scrollbar-thumb {
  background: #7e869e; /* scrollbar thumb color */
  border-radius: 2px;
}

.think-scroll::-webkit-scrollbar-thumb:hover {
  background: #555; /* scrollbar thumb color on hover */
}

.vertical-scrollbar ::-webkit-scrollbar {
  width: 4px;
}

.vertical-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #7e869e;
  border-radius: 2px;
}

.vertical-scrollbar ::-webkit-scrollbar-track {
  background-color: transparent;
}

.select-person::-webkit-scrollbar {
  display: none;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.truncate2strings {
  margin: 0;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.truncateString {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ----opacity faded text ------  */
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  width: 150px;
  text-overflow: ellipsis;
  -webkit-mask-image: linear-gradient(to left, transparent 5%, white 70%);
  mask-image: linear-gradient(to left, transparent 5%, white 70%);
}

.truncate-text-analysis {
  white-space: nowrap;
  overflow: hidden;
  width: 240px;
  text-overflow: ellipsis;
  -webkit-mask-image: linear-gradient(to left, transparent 10%, white 25%);
  mask-image: linear-gradient(to left, transparent 10%, white 25%);
}

.truncate-text-smallCards {
  white-space: nowrap;
  overflow: hidden;
  height: 20px;
  vertical-align: middle;
  width: 260px;
  text-overflow: ellipsis;
  -webkit-mask-image: linear-gradient(to left, transparent 5%, white 70%);
  mask-image: linear-gradient(to left, transparent 5%, white 70%);
}

.truncate-text-people {
  white-space: nowrap;
  overflow: hidden;
  width: 100px;
  -webkit-mask-image: linear-gradient(to left, transparent -120px, black);
  mask-image: linear-gradient(to left, transparent -120px, black);
}

.truncate-text-people2 {
  white-space: nowrap;
  overflow: hidden;
  width: 145px;
  -webkit-mask-image: linear-gradient(to left, transparent -120px, black);
  mask-image: linear-gradient(to left, transparent -120px, black);
}

.truncate-text2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(
    to top,
    transparent 0%,
    transparent 0.2%,
    black 90%,
    black 100%
  );
  mask-image: linear-gradient(
    to top,
    transparent 0%,
    transparent 0.2%,
    black 90%,
    black 100%
  );
}

.truncate-text-chart {
  white-space: nowrap;
  overflow: hidden;
  width: 50px;
  -webkit-mask-image: linear-gradient(to left, transparent -120px, black);
  mask-image: linear-gradient(to left, transparent -120px, black);
}

.truncate-text-chart2 {
  white-space: nowrap;
  overflow: hidden;
  width: 40px;
  -webkit-mask-image: linear-gradient(to left, transparent -120px, black);
  mask-image: linear-gradient(to left, transparent -120px, black);
}

/* ------------filter---------------- */
.boxWithScroll {
  overflow-y: auto;
  max-height: 140px;
}

.boxWithScroll::-webkit-scrollbar {
  width: 4px;
}

.boxWithScroll::-webkit-scrollbar-thumb {
  background-color: #7e869e;
  border-radius: 7px;
}

.boxWithScroll::-webkit-scrollbar-track {
  background-color: #f0f2f5;
  /* Scrollbar track background color */
}

.select-option {
  padding: 50px;
}

#chakra-toast-manager-bottom {
  margin-bottom: 60px !important;
}

.text-focus-in {
  -webkit-animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
    both;
  animation: text-focus-in 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(3px);
    filter: blur(3px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(3px);
    filter: blur(3px);
    opacity: 0;
  }

  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@keyframes animating-multiple-properties {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

#sentry-feedback {
  --bottom: 70px;
  --background: #fbfbfb;
  --cancel-background-hover: #ccd2e3;
  --background-hover: #134e88;
  --submit-background: #134e88;
  --submit-background-hover: #205f9d;
  --submit-border: #134e88;
  --foreground: #0a2540;
  --input-foreground: #0a2540;
  --input-border: 1px solid #0a2540;
  --input-outline-focus: #0a2540;
}

ul {
  list-style-type: disc;
}

.pagination_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  list-style-type: none;
  gap: 6px;
}

.pagination_btn {
  display: inline-block;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 100%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  -webkit-user-select: none;
  user-select: none;
}

.pagination_active-btn {
  background: #0a2540;
  color: #fff;
}

.notifications-list {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.notifications-list::-webkit-scrollbar {
  display: none;
}

.css-w52dox[aria-checked='true'],
.css-w52dox[data-checked] {
  background: #0a2540 !important;
}

/* Background color classes */
.bg-research {
  background-color: #F0F2FA;
}

.bg-companies {
  background-color: #FFFFFF;
}
